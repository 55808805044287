import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"

import Amplify, { API, graphqlOperation } from "aws-amplify"
import { getProject } from "../../graphql/queries"

import awsExports from "../../aws-exports"

const projectId = "4d229780-3240-4f24-aaef-02ff8a61a6a2"

Amplify.configure(awsExports)

const BeamMeUp = (props) => {
  // const [formState, setFormState] = useState(initialState)
  const [rooms, setRooms] = useState([])

  useEffect(() => {
    fetchProject(projectId)
  }, [])

  // function setInput(key, value) {
  //   console.log("key: ", key)
  //   console.log("value: ", value)

  //   setFormState({ ...formState, [key]: value })
  // }

  async function fetchProject(projectId) {
    try {
      // console.log("project: ", projectId)
      const projectData = await API.graphql(
        graphqlOperation(getProject, { id: projectId })
      )
      const projectRooms = projectData.data.getProject.rooms.items
      setRooms(projectRooms)
      // console.log("project rooms: ", projectRooms)
    } catch (err) {
      console.log("error fetching projects: ", err)
    }
  }

  const RoomList = () => {
    const [timeLeft, setTimeLeft] = useState(5)

    useEffect(() => {
      // exit early when we reach 0

      if (!timeLeft) {
        navigate("https://keplersgardens.net/" + roomToJoin.hubsId)
      }

      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId)
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }, [timeLeft])

    const maxPerRoom = 25

    const sortedRooms = rooms.sort(function (a, b) {
      return (
        b.userCountRoom +
        b.userCountLobby -
        (a.userCountRoom + a.userCountLobby)
      )
    })
    sortedRooms.forEach(room => {
      if (room.userCountRoom + room.userCountLobby < maxPerRoom) {
        room.full = false
      } else {
        room.full = true
      }
      room.userCountTotal = room.userCountRoom + room.userCountLobby
    })

    const availableRooms = sortedRooms.filter(room => !room.full)
    var userCanJoin = true
    var roomToJoin = {}
    if (availableRooms.length > 0) {
      userCanJoin = true
      roomToJoin = availableRooms[0]
      // navigate("https://keplersgardens.net/" + roomToJoin.hubsId)
    } else {
      userCanJoin = false
    }
    // if (userCanJoin) {
    //   if (!timerRunning) {
    //     setTimerRunning(true)
    //   }
    // }
    return (
      <div>
        {userCanJoin ? (
          <h1>
            In {timeLeft} sec we are beaming you up to Room {roomToJoin.hubsId}
          </h1>
        ) : (
          <h1>
            No rooms available to join right now. Please check back soon.{" "}
          </h1>
        )}
        <h2>Stats for nerds: </h2>
        <p>Assuming max user per room: {maxPerRoom}</p>
        <h3>Rooms sorted by user count</h3>
        {sortedRooms.map((room, index) => {
          // const full = false
          // const userCountTotal = room.userCountRoom + room.userCountLobby
          // if (userCountTotal > 25) { full = true }
          return (
            <div key={index}>
              <h3>
                Room {index + 1}{" "}
                <span style={{ color: "lightgray" }}>(ID: {room.hubsId})</span>
              </h3>
              <p key="userCountLobby">Users in Lobby: {room.userCountLobby}</p>
              <p key="userCountRoom">Users in Room: {room.userCountRoom}</p>
              <p key="userCountTotal">
                Total Live Users: {room.userCountTotal} / {maxPerRoom}
              </p>
              <p>Room full? {room.full ? "Full" : "Users can join"}</p>
            </div>
          )
        })}
        <h3>Filtering out rooms that are full</h3>
        {availableRooms.map((room, index) => {
          // const full = false
          // const userCountTotal = room.userCountRoom + room.userCountLobby
          // if (userCountTotal > 25) { full = true }
          return (
            <div key={index}>
              <h3>
                Room {index + 1}{" "}
                <span style={{ color: "lightgray" }}>(ID: {room.hubsId})</span>
              </h3>
              <p key="userCountLobby">Users in Lobby: {room.userCountLobby}</p>
              <p key="userCountRoom">Users in Room: {room.userCountRoom}</p>
              <p key="userCountTotal">
                Total Live Users: {room.userCountTotal} / {maxPerRoom}
              </p>
            </div>
          )
        })}
      </div>
    )
  }

  // async function addProject() {
  //   try {
  //     // if (!formState.name || !formState.description) return
  //     const project = { ...formState }
  //     setProjects([...projects, project])
  //     setFormState(initialState)
  //     await API.graphql(graphqlOperation(createProject, { input: project }))
  //   } catch (err) {
  //     console.log('error creating project:', err)
  //   }
  // }

  // // subscription
  // function subscribeProjects() {
  //   try {
  //     API.graphql(graphqlOperation(onUpdateProject)).subscribe({
  //       next: (projectData) => console.log("Updated Project: ", projectData)
  //     });
  //   }
  //   catch (err) {
  //     console.log("subscription error: ", err)
  //   }
  // }

  useEffect(() => {
    props.addMarker([])
  }, [])

  return (
    <div>
      <SEO title="Forwarding you to your room" />
      <div>
        <RoomList />
      </div>
    </div>
  )
}

export default BeamMeUp
